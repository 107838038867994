<template>
  <b-modal
    ref="modalMultipleReiseterminBearbeiten"
    id="modalMultipleReiseterminBearbeiten"
    size="xl"
    :title="title"
    @show="onShow"
    @hide="onHide"
    centered
    class="m-0 p-0"
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-overlay :show="isLoading">
      <div v-if="isReisemetadata">
        Felder, die für (zukünftige) Reisetermine der Saison übernommen werden, auswählen:
      </div>
      <div v-else>Felder zum Bearbeiten auswählen:</div>
      <div class="d-flex align-items-center mt-2" style="gap: 8px">
        <Multiselect
          v-model="editFields"
          :options="orderedFieldEditOptions"
          selectLabel=""
          selectGroupLabel=""
          deselectLabel=""
          deselectGroupLabel=""
          :close-on-select="false"
          :searchable="false"
          :multiple="true"
          label="label"
          track-by="key"
          selectedLabel="Ausgewählt"
          :placeholder="null"
          class="w-75"
          size="md"
        ></Multiselect>
        <i
          v-if="editFields.length > 0"
          class="fas fa-times text-danger align-self-center"
          @click="editFields = []"
          style="cursor: pointer"
        ></i>
      </div>
      <div class="mt-4 grid">
        <b-form-group label="Overnight Outbound" v-if="labelsFromEditFields.includes('Overnight Outbound')">
          <div class="d-flex">
            <b-form-select size="sm" v-model="$v.form.overnightOutbound.$model">
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option :value="0">0</b-form-select-option>
              <b-form-select-option :value="1">+1</b-form-select-option>
              <b-form-select-option :value="2">+2</b-form-select-option>
            </b-form-select>
          </div>
        </b-form-group>
        <b-form-group label="Overnight Inbound" v-if="labelsFromEditFields.includes('Overnight Inbound')">
          <div class="d-flex">
            <b-form-select size="sm" v-model="$v.form.overnightInbound.$model">
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option :value="0">0</b-form-select-option>
              <b-form-select-option :value="1">+1</b-form-select-option>
              <b-form-select-option :value="2">+2</b-form-select-option>
            </b-form-select>
          </div>
        </b-form-group>
        <b-form-group label="Kalk Pax" v-if="labelsFromEditFields.includes('Kalk Pax')">
          <b-form-input
            size="sm"
            v-model="$v.form.kalkPax.$model"
            :number="true"
            type="number"
            min="0"
            max="365"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Erwartete Pax" v-if="labelsFromEditFields.includes('Erwartete Pax')">
          <b-form-input
            size="sm"
            v-model="$v.form.erwartetePax.$model"
            :number="true"
            type="number"
            min="0"
            max="365"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Min Pax" v-if="labelsFromEditFields.includes('Min Pax')">
          <b-form-input
            size="sm"
            v-model="$v.form.minPax.$model"
            :number="true"
            type="number"
            min="0"
            max="365"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Max Pax" v-if="labelsFromEditFields.includes('Max Pax')">
          <b-form-input
            size="sm"
            v-model="$v.form.maxPax.$model"
            :number="true"
            type="number"
            min="0"
            max="365"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Durchführbare Min Pax"
          v-if="labelsFromEditFields.includes('Durchführbare Min Pax')"
        >
          <b-form-input
            size="sm"
            v-model="$v.form.durchfuehrbareMinPax.$model"
            :number="true"
            type="number"
            min="0"
            max="365"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Zielland Zielflughäfen"
          v-if="labelsFromEditFields.includes('Zielland Zielflughäfen')"
        >
          <AirportsSucheMultiselect
            v-model="$v.form.ziellandZielflughaefenIataCodes.$model"
            :isMultipleAllowed="true"
            :openDirection="'top'"
            :filterCountry="reiseterminEditData?.reiseterminkuerzel?.substring(0, 2)"
            id="abflughafen"
          ></AirportsSucheMultiselect>
        </b-form-group>
        <b-form-group
          label="Zielland Zielflughafen Priorität"
          v-if="labelsFromEditFields.includes('Zielland Zielflughafen Prio')"
        >
          <b-form-select
            size="sm"
            :options="$v.form.ziellandZielflughaefenIataCodes.$model"
            v-model="$v.form.ziellandZielflughafenPrio.$model"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Zielland Abflughäfen"
          v-if="labelsFromEditFields.includes('Zielland Abflughäfen')"
        >
          <AirportsSucheMultiselect
            v-model="$v.form.ziellandAbflughaefenIataCodes.$model"
            :isMultipleAllowed="true"
            :openDirection="'top'"
            :filterCountry="reiseterminEditData?.reiseterminkuerzel?.substring(0, 2)"
            id="abflughafen"
          ></AirportsSucheMultiselect>
        </b-form-group>
        <b-form-group
          label="Zielland Abflughafen Priorität"
          v-if="labelsFromEditFields.includes('Zielland Abflughafen Prio')"
        >
          <b-form-select
            size="sm"
            :options="$v.form.ziellandAbflughaefenIataCodes.$model"
            v-model="$v.form.ziellandAbflughafenPrio.$model"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Erw. Termindurchführungsquote"
          v-if="labelsFromEditFields.includes('Erw. Termindurchführungsquote')"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              v-model="$v.form.erwarteteTermindurchfuehrungsquote.$model"
              :number="true"
              type="number"
            ></b-form-input>
            <template #append>
              <b-input-group-text>%</b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="EK kalk. Vornacht DZ p.P."
          v-if="labelsFromEditFields.includes('EK kalk. Vornacht DZ p.P.')"
        >
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              v-model="$v.form.einkaufspreisVornachtDzProPerson.$model"
              :number="true"
              type="number"
            ></b-form-input>
            <template #append>
              <b-input-group-text>€</b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
        <b-form-group label="SKR Flugangebot" v-if="labelsFromEditFields.includes('SKR Flugangebot')">
          <b-input-group size="sm">
            <b-form-input
              size="sm"
              v-model="$v.form.skrFlugangebot.$model"
              :number="true"
              type="number"
            ></b-form-input>
            <template #append>
              <b-input-group-text>€</b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Transferszenario Hin"
          v-if="labelsFromEditFields.includes('Transferszenario Hin')"
        >
          <b-form-select v-model="$v.form.transferszenarioHin.$model" size="sm">
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option :value="'TX_ohne'">TX</b-form-select-option>
            <b-form-select-option :value="'T1_1inkl'">T1</b-form-select-option>
            <b-form-select-option :value="'T2_1inkl_exkl_mögl'">T2</b-form-select-option>
            <b-form-select-option :value="'T3_unbegr_inkl'">T3</b-form-select-option>
            <b-form-select-option :value="'T4_unbegr_exkl'">T4</b-form-select-option>
            <b-form-select-option :value="'T5_fest_inkl'">T5</b-form-select-option>
            <b-form-select-option value="T5_2">T5_2</b-form-select-option>
            <b-form-select-option value="T5_3">T5_3</b-form-select-option>
            <b-form-select-option :value="'T6'">T6</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Transferszenario Rück"
          v-if="labelsFromEditFields.includes('Transferszenario Rück')"
        >
          <b-form-select v-model="$v.form.transferszenarioRueck.$model" size="sm">
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option :value="'TX_ohne'">TX</b-form-select-option>
            <b-form-select-option :value="'T1_1inkl'">T1</b-form-select-option>
            <b-form-select-option :value="'T2_1inkl_exkl_mögl'">T2</b-form-select-option>
            <b-form-select-option :value="'T3_unbegr_inkl'">T3</b-form-select-option>
            <b-form-select-option :value="'T4_unbegr_exkl'">T4</b-form-select-option>
            <b-form-select-option :value="'T5_fest_inkl'">T5</b-form-select-option>
            <b-form-select-option value="T5_2">T5_2</b-form-select-option>
            <b-form-select-option value="T5_3">T5_3</b-form-select-option>
            <b-form-select-option :value="'T6'">T6</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-checkbox
          v-model="$v.form.hotelstartVorLandung.$model"
          class="align-self-center"
          v-if="labelsFromEditFields.includes('Hotelstart vor Landung')"
        >
          Hotelstart vor Landung
        </b-form-checkbox>
        <b-form-checkbox
          v-model="$v.form.rueckflugNachHotelende.$model"
          class="align-self-center"
          v-if="labelsFromEditFields.includes('Rückflug nach Hotelende')"
        >
          Rückflug nach Hotelende
        </b-form-checkbox>

        <b-form-checkbox
          v-model="$v.form.isKritischeDestination.$model"
          class="align-self-center"
          v-if="labelsFromEditFields.includes('Kritische Destination')"
        >
          Kritische Destination
        </b-form-checkbox>
        <b-form-group label="Hotelnächte" v-if="labelsFromEditFields.includes('Hotelnächte')">
          <b-form-input
            size="sm"
            v-model="$v.form.hotelnaechte.$model"
            :number="true"
            type="number"
            min="0"
            max="365"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Transferkosten Hin" v-if="labelsFromEditFields.includes('Transferkosten Hin')">
          <b-form-input
            size="sm"
            v-model="$v.form.transferkostenHin.$model"
            :number="true"
            type="number"
            min="0"
            max="365"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Transferkosten Rück" v-if="labelsFromEditFields.includes('Transferkosten Rück')">
          <b-form-input
            size="sm"
            v-model="$v.form.transferkostenRueck.$model"
            :number="true"
            type="number"
            min="0"
            max="365"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Transferkosten Währung"
          v-if="labelsFromEditFields.includes('Transferkosten Währung')"
        >
          <Multiselect
            v-model="$v.form.transferkostenWaehrung.$model"
            :options="currenciesOptions"
            :placeholder="'---'"
            :selectLabel="''"
            :selectGroupLabel="''"
            :deselectLabel="''"
            :deselectGroupLabel="''"
          ></Multiselect
        ></b-form-group>
        <b-form-group label="Frühester Abflug" v-if="labelsFromEditFields.includes('Frühester Abflug')">
          <DatePicker
            :lang="langConfig"
            type="time"
            class="w-100"
            v-model="$v.form.fruehesterAbflug.$model"
            :format="formatDatetimepicker"
            :show-second="false"
            value-type="format"
          >
          </DatePicker
        ></b-form-group>
        <b-form-group label="Spätester Abflug" v-if="labelsFromEditFields.includes('Spätester Abflug')">
          <DatePicker
            :lang="langConfig"
            type="time"
            class="w-100"
            v-model="$v.form.spaetesterAbflug.$model"
            :format="formatDatetimepicker"
            :show-second="false"
            value-type="format"
          />
          <div class="d-flex align-items-center mt-3">
            <b-form-checkbox size="lg" v-model="$v.form.restriktionenHinRollover.$model" />
            <span class="ml-1" style="font-size: 14px">+1</span>
          </div>
        </b-form-group>
        <b-form-group label="Früheste Ankunft" v-if="labelsFromEditFields.includes('Früheste Ankunft')">
          <DatePicker
            :lang="langConfig"
            type="time"
            class="w-100"
            v-model="$v.form.fruehesteAnkunft.$model"
            :format="formatDatetimepicker"
            :show-second="false"
            value-type="format"
          >
          </DatePicker
        ></b-form-group>
        <b-form-group label="Späteste Ankunft" v-if="labelsFromEditFields.includes('Späteste Ankunft')">
          <DatePicker
            :lang="langConfig"
            type="time"
            class="w-100"
            v-model="$v.form.spaetesteAnkunft.$model"
            :format="formatDatetimepicker"
            :show-second="false"
            value-type="format"
          />
          <div class="d-flex align-items-center mt-3">
            <b-form-checkbox size="lg" v-model="$v.form.restriktionenRueckRollover.$model" />
            <span class="ml-1" style="font-size: 14px">+1</span>
          </div>
        </b-form-group>
        <b-form-group label="Releasefrist" v-if="labelsFromEditFields.includes('Releasefrist')">
          <div v-if="form.releasefrist === null" class="bg-primary plus-sign" @click="onAddReleasefrist">
            +
          </div>
          <div v-else v-for="item in form.releasefrist" :key="'releasefrist-' + item.id">
            <b-form-group class="widthEditField">
              <template #label>
                <div class="d-flex align-items-center" style="gap: 4px">
                  <div>{{ `${item.id}. Releasefrist` }}</div>
                  <div
                    class="bg-primary plus-sign"
                    v-if="form.releasefrist?.length === item.id"
                    @click="onRemoveReleasefrist"
                  >
                    -
                  </div>
                  <div
                    v-if="form.releasefrist?.length === item.id"
                    class="bg-primary plus-sign"
                    @click="onAddReleasefrist"
                  >
                    +
                  </div>
                </div>
              </template>
              <b-form-input
                size="sm"
                v-model="item.value"
                :number="true"
                type="number"
                min="0"
                max="365"
                step="1"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </b-form-group>
        <b-form-checkbox
          size="lg"
          v-model="$v.form.vorzeitigGarantiert.$model"
          class="align-self-center"
          v-if="labelsFromEditFields.includes('Vorzeitig garantiert')"
        >
          Vorzeitig garantiert
        </b-form-checkbox>
        <b-form-checkbox
          size="lg"
          v-model="$v.form.istZusatztermin.$model"
          class="align-self-center"
          v-if="labelsFromEditFields.includes('Zusatztermin')"
        >
          Zusatztermin
        </b-form-checkbox>
        <b-form-group label="TH Inlandsflug" v-if="labelsFromEditFields.includes('TH Inlandsflug')">
          <b-form-input
            size="sm"
            v-model="$v.form.thInlandsflug.$model"
            :number="true"
            type="number"
            min="0"
            max="365"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="TH Langstrecke" v-if="labelsFromEditFields.includes('TH Langstrecke')">
          <b-form-input
            size="sm"
            v-model="$v.form.thLangstrecke.$model"
            :number="true"
            type="number"
            min="0"
            max="365"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
      </div>
    </b-overlay>
    <template #modal-footer="{ hide }" class="pl-1">
      <b-button class="mr-4" @click="handleSubmit(hide)" variant="primary">Speichern</b-button>
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>
<script>
import Multiselect from 'vue-multiselect';
import { validationMixin } from 'vuelidate';
import AirportsSucheMultiselect from '@/components/common/airports-suche-multiselect.vue';
import {
  EDIT_REISETERMIN_METADATA_MULTIPLE,
  BULK_EDIT_REISETERMIN_VALUES,
} from '@/core/produkte/reisetermine/stores/reiseterminplanung.module';
import currenciesJson from '@/core/common/helpers/currencies.json';
import DatePicker from 'vue2-datepicker';
import { langConfig } from '@/core/common/helpers/utils.js';

export default {
  mixins: [validationMixin],
  props: {
    reiseterminEditData: {
      type: Array,
      required: true,
    },
    preexistingFormData: { type: Object, default: null },
    changedFields: { type: Array },
    title: { type: String },
    isReisemetadata: { type: Boolean, default: false },
  },
  components: {
    Multiselect,
    DatePicker,
    AirportsSucheMultiselect,
  },
  computed: {
    orderedFieldEditOptions() {
      return this.fieldEditOptions.slice().sort((a, b) => a.label.localeCompare(b.label));
    },
    ziellandAbflughaefenIataCodes() {
      return this.form?.ziellandAbflughaefenIataCodes;
    },
    ziellandZielflughaefenIataCodes() {
      return this.form?.ziellandZielflughaefenIataCodes;
    },
    reisekuerzelToEdit() {
      if (
        this.reiseterminEditData?.length > 0 &&
        new Set(this.reiseterminEditData.map(r => r.reiseterminkuerzel.substring(0, 6))).size === 1
      ) {
        return this.reiseterminEditData[0].reiseterminkuerzel.substring(0, 6);
      }
      return '';
    },
    labelsFromEditFields() {
      return this.editFields?.map(f => f.label);
    },
    keysFromEditFields() {
      return this.editFields?.map(f => f.key);
    },
  },
  mounted() {
    this.currenciesOptions = Object.keys(currenciesJson);
  },
  watch: {
    ziellandAbflughaefenIataCodes: {
      handler(v) {
        if (v?.length > 0 && this.form.ziellandAbflughafenPrio === null) {
          this.form.ziellandAbflughafenPrio = v[0];
        }
        if (v?.length === 0) {
          this.form.ziellandAbflughafenPrio = null;
        }
        if (!v?.some(fh => fh.flughafenIataCode === this.form.ziellandAbflughafenPrio)) {
          if (v?.length > 0) {
            this.form.ziellandAbflughafenPrio = v[0];
          } else {
            this.form.ziellandAbflughafenPrio = null;
          }
        }
      },
    },
    ziellandZielflughaefenIataCodes: {
      handler(v) {
        if (v?.length > 0 && this.form.ziellandZielflughafenPrio === null) {
          this.form.ziellandZielflughafenPrio = v[0];
        }
        if (v?.length === 0) {
          this.form.ziellandZielflughafenPrio = null;
        }
        if (!v?.some(fh => fh.flughafenIataCode === this.form.ziellandZielflughafenPrio)) {
          if (v?.length > 0) {
            this.form.ziellandZielflughafenPrio = v[0];
          } else {
            this.form.ziellandZielflughafenPrio = null;
          }
        }
      },
    },
  },
  data() {
    return {
      langConfig,
      wochentagOptions: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
      isLoading: false,
      currenciesOptions: null,
      editFields: [],
      fieldEditOptions: [
        { label: 'Overnight Outbound', key: 'overnightOutbound', reiseterminValues: false },
        { label: 'Overnight Inbound', key: 'overnightInbound', reiseterminValues: false },
        { label: 'Kalk Pax', key: 'kalkPax', reiseterminValues: false },
        { label: 'Erwartete Pax', key: 'erwartetePax', reiseterminValues: false },
        { label: 'Min Pax', key: 'minPax', reiseterminValues: false },
        { label: 'Max Pax', key: 'maxPax', reiseterminValues: false },
        { label: 'Durchführbare Min Pax', key: 'durchfuehrbareMinPax', reiseterminValues: false },
        { label: 'Zielland Zielflughäfen', key: 'ziellandZielflughaefenIataCodes', reiseterminValues: false },
        { label: 'Zielland Zielflughafen Prio', key: 'ziellandZielflughafenPrio', reiseterminValues: false },
        { label: 'Zielland Abflughäfen', key: 'ziellandAbflughaefenIataCodes', reiseterminValues: false },
        { label: 'Zielland Abflughafen Prio', key: 'ziellandAbflughafenPrio', reiseterminValues: false },
        { label: 'SKR Flugangebot', key: 'skrFlugangebot', reiseterminValues: false },
        { label: 'Transferszenario Hin', key: 'transferszenarioHin', reiseterminValues: false },
        { label: 'Transferszenario Rück', key: 'transferszenarioRueck', reiseterminValues: false },
        { label: 'Hotelstart vor Landung', key: 'hotelstartVorLandung', reiseterminValues: false },
        { label: 'Rückflug nach Hotelende', key: 'rueckflugNachHotelende', reiseterminValues: false },
        { label: 'Kritische Destination', key: 'isKritischeDestination', reiseterminValues: false },
        { label: 'Hotelnächte', key: 'hotelnaechte', reiseterminValues: false },
        { label: 'Transferkosten Hin', key: 'transferkostenHin', reiseterminValues: false },
        { label: 'Transferkosten Rück', key: 'transferkostenRueck', reiseterminValues: false },
        { label: 'Transferkosten Währung', key: 'transferkostenWaehrung', reiseterminValues: false },
        { label: 'Frühester Abflug', key: 'fruehesterAbflug', reiseterminValues: false },
        { label: 'Spätester Abflug', key: 'spaetesterAbflug', reiseterminValues: false },
        { label: 'Früheste Ankunft', key: 'fruehesteAnkunft', reiseterminValues: false },
        { label: 'Späteste Ankunft', key: 'spaetesteAnkunft', reiseterminValues: false },
        { label: 'Releasefrist', key: 'releasefrist', reiseterminValues: false },
        { label: 'Vorzeitig garantiert', key: 'vorzeitigGarantiert', reiseterminValues: true },
        { label: 'Zusatztermin', key: 'istZusatztermin', reiseterminValues: true },
        { label: 'TH Inlandsflug', key: 'thInlandsflug', reiseterminValues: true },
        { label: 'TH Langstrecke', key: 'thLangstrecke', reiseterminValues: true },
        {
          label: 'Erw. Termindurchführungsquote',
          key: 'erwarteteTermindurchfuehrungsquote',
          reiseterminValues: false,
        },
        {
          label: 'EK kalk. Vornacht DZ p.P.',
          key: 'einkaufspreisVornachtDzProPerson',
          reiseterminValues: false,
        },
      ],
      formatDatetimepicker: 'HH:mm',
      form: {
        overnightOutbound: null,
        overnightInbound: null,
        ziellandAbflughaefenIataCodes: null,
        ziellandZielflughaefenIataCodes: null,
        ziellandAbflughafenPrio: null,
        ziellandZielflughafenPrio: null,
        hotelstartVorLandung: null,
        rueckflugNachHotelende: null,
        isKritischeDestination: null,
        kalkPax: null,
        erwartetePax: null,
        minPax: null,
        maxPax: null,
        durchfuehrbareMinPax: null,
        skrFlugangebot: null,
        transferszenarioHin: null,
        transferszenarioRueck: null,
        hotelnaechte: null,
        transferkostenHin: null,
        transferkostenRueck: null,
        transferkostenWaehrung: null,
        fruehesterAbflug: null,
        spaetesterAbflug: null,
        fruehesteAnkunft: null,
        spaetesteAnkunft: null,
        releasefrist: null,
        vorzeitigGarantiert: null,
        istZusatztermin: null,
        thLangstrecke: null,
        thInlandsflug: null,
        einkaufspreisVornachtDzProPerson: null,
        erwarteteTermindurchfuehrungsquote: null,
        restriktionenHinRollover: null,
        restriktionenRueckRollover: null,
      },
    };
  },
  validations: {
    form: {
      overnightOutbound: {},
      overnightInbound: {},
      ziellandAbflughaefenIataCodes: {},
      ziellandZielflughaefenIataCodes: {},
      ziellandAbflughafenPrio: {},
      ziellandZielflughafenPrio: {},
      hotelstartVorLandung: {},
      rueckflugNachHotelende: {},
      isKritischeDestination: {},
      kalkPax: {},
      erwartetePax: {},
      minPax: {},
      maxPax: {},
      durchfuehrbareMinPax: {},
      skrFlugangebot: {},
      transferszenarioHin: {},
      transferszenarioRueck: {},
      hotelnaechte: {},
      transferkostenHin: {},
      transferkostenRueck: {},
      transferkostenWaehrung: {},
      fruehesterAbflug: {},
      spaetesterAbflug: {},
      fruehesteAnkunft: {},
      spaetesteAnkunft: {},
      releasefrist: {},
      vorzeitigGarantiert: {},
      istZusatztermin: {},
      thInlandsflug: {},
      thLangstrecke: {},
      einkaufspreisVornachtDzProPerson: {},
      erwarteteTermindurchfuehrungsquote: {},
      restriktionenHinRollover: {},
      restriktionenRueckRollover: {},
    },
  },
  methods: {
    formatParams() {
      if (this.editFields?.length > 0 && this.reiseterminEditData?.length > 0) {
        const params = this.reiseterminEditData.reduce((acc, curr) => {
          acc[curr.startdatum] = {
            overnightInbound: this.keysFromEditFields.includes('overnightInbound')
              ? this.form.overnightInbound
              : curr.metadata.overnightInbound,
            overnightOutbound: this.keysFromEditFields.includes('overnightOutbound')
              ? this.form.overnightOutbound
              : curr.metadata.overnightOutbound,
            hotelstartVorLandung: this.keysFromEditFields.includes('hotelstartVorLandung')
              ? this.form.hotelstartVorLandung
              : curr.metadata.hotelstartVorLandung,
            rueckflugNachHotelende: this.keysFromEditFields.includes('rueckflugNachHotelende')
              ? this.form.rueckflugNachHotelende
              : curr.metadata.rueckflugNachHotelende,
            kalkPax: this.keysFromEditFields.includes('kalkPax') ? this.form.kalkPax : curr.metadata.kalkPax,
            minPax: this.keysFromEditFields.includes('minPax') ? this.form.minPax : curr.metadata.minPax,
            maxPax: this.keysFromEditFields.includes('maxPax') ? this.form.maxPax : curr.metadata.maxPax,
            erwartetePax: this.keysFromEditFields.includes('erwartetePax')
              ? this.form.erwartetePax
              : curr.metadata.erwartetePax,
            durchfuehrbareMinPax: this.keysFromEditFields.includes('durchfuehrbareMinPax')
              ? this.form.durchfuehrbareMinPax
              : curr.metadata.durchfuehrbareMinPax,
            passdatenFrist: curr.metadata.passdatenFrist,
            aufpreisBeiVerspaetetenPassdaten: curr.metadata.aufpreisBeiVerspaetetenPassdaten,
            transferszenarioHin: this.keysFromEditFields.includes('transferszenarioHin')
              ? this.form.transferszenarioHin
              : curr.metadata.transferszenarioHin,
            transferszenarioRueck: this.keysFromEditFields.includes('transferszenarioRueck')
              ? this.form.transferszenarioRueck
              : curr.metadata.transferszenarioRueck,
            skrFlugangebot: this.keysFromEditFields.includes('skrFlugangebot')
              ? this.form.skrFlugangebot
              : curr.metadata.skrFlugangebot,
            ziellandZielflughaefenIataCodes: this.keysFromEditFields.includes(
              'ziellandZielflughaefenIataCodes'
            )
              ? this.form.ziellandZielflughaefenIataCodes
              : curr.metadata.ziellandZielflughaefen.map(fh => fh.flughafenIataCode),
            ziellandAbflughaefenIataCodes: this.keysFromEditFields.includes('ziellandAbflughaefenIataCodes')
              ? this.form.ziellandAbflughaefenIataCodes
              : curr.metadata.ziellandAbflughaefen.map(fh => fh.flughafenIataCode),
            ziellandZielflughafenPrio: this.keysFromEditFields.includes('ziellandZielflughafenPrio')
              ? this.form.ziellandZielflughafenPrio
              : curr.metadata.ziellandZielflughaefen.find(fh => fh.isPrio)?.flughafenIataCode || null,
            ziellandAbflughafenPrio: this.keysFromEditFields.includes('ziellandAbflughafenPrio')
              ? this.form.ziellandAbflughafenPrio
              : curr.metadata.ziellandAbflughaefen.find(fh => fh.isPrio)?.flughafenIataCode || null,
            isKritischeDestination: this.keysFromEditFields.includes('isKritischeDestination')
              ? this.form.isKritischeDestination
              : curr.metadata.isKritischeDestination,
            hotelnaechte: this.keysFromEditFields.includes('hotelnaechte')
              ? this.form.hotelnaechte
              : curr.metadata.hotelnaechte,
            transferkostenHin: this.keysFromEditFields.includes('transferkostenHin')
              ? this.form.transferkostenHin
              : curr.metadata.transferkostenHin,
            transferkostenRueck: this.keysFromEditFields.includes('transferkostenRueck')
              ? this.form.transferkostenRueck
              : curr.metadata.transferkostenRueck,
            transferkostenWaehrung: this.keysFromEditFields.includes('transferkostenWaehrung')
              ? this.form.transferkostenWaehrung
              : curr.metadata.transferkostenWaehrung,
            fruehesterAbflug: this.keysFromEditFields.includes('fruehesterAbflug')
              ? this.form.fruehesterAbflug?.length >= 8
                ? this.form.fruehesterAbflug
                : this.form.fruehesterAbflug + ':00'
              : curr.metadata.fruehesterAbflug,
            spaetesterAbflug: this.keysFromEditFields.includes('spaetesterAbflug')
              ? this.form.spaetesterAbflug?.length >= 8
                ? this.form.spaetesterAbflug
                : this.form.spaetesterAbflug + ':00'
              : curr.metadata.spaetesterAbflug,
            fruehesteAnkunft: this.keysFromEditFields.includes('fruehesteAnkunft')
              ? this.form.fruehesteAnkunft?.length >= 8
                ? this.form.fruehesteAnkunft
                : this.form.fruehesteAnkunft + ':00'
              : curr.metadata.fruehesteAnkunft,
            spaetesteAnkunft: this.keysFromEditFields.includes('spaetesteAnkunft')
              ? this.form.spaetesteAnkunft?.length >= 8
                ? this.form.spaetesteAnkunft
                : this.form.spaetesteAnkunft + ':00'
              : curr.metadata.spaetesteAnkunft,
            releasefrist:
              this.keysFromEditFields.includes('releasefrist') && this.form.releasefrist !== null
                ? this.form.releasefrist.map(item => item.value)
                : curr.metadata.releasefrist,
            einkaufspreisVornachtDzProPerson: this.keysFromEditFields.includes(
              'einkaufspreisVornachtDzProPerson'
            )
              ? this.form.einkaufspreisVornachtDzProPerson
              : curr.metadata.einkaufspreisVornachtDzProPerson,
            erwarteteTermindurchfuehrungsquote: this.keysFromEditFields.includes(
              'erwarteteTermindurchfuehrungsquote'
            )
              ? this.form.erwarteteTermindurchfuehrungsquote
              : curr.metadata.erwarteteTermindurchfuehrungsquote,
            restriktionenHinRollover: this.keysFromEditFields.includes('spaetesteAnkunft')
              ? this.form.restriktionenHinRollover
              : curr.metadata.restriktionenHinRollover,
            restriktionenRueckRollover: this.keysFromEditFields.includes('spaetesterAbflug')
              ? +this.form.restriktionenRueckRollover
              : curr.metadata.restriktionenRueckRollover,
          };
          return acc;
        }, {});
        return params;
      } else return null;
    },
    formatParamsReiseterminValues() {
      if (this.editFields?.length > 0 && this.reiseterminEditData?.length > 0) {
        const params = this.reiseterminEditData.reduce((acc, curr) => {
          acc[curr.startdatum] = {
            vorzeitigGarantiert: this.keysFromEditFields.includes('vorzeitigGarantiert')
              ? this.form.vorzeitigGarantiert
              : curr.vorzeitigGarantiert,
            istZusatztermin: this.keysFromEditFields.includes('istZusatztermin')
              ? this.form.istZusatztermin
              : curr.istZusatztermin,
            thLangstrecke: this.keysFromEditFields.includes('thLangstrecke')
              ? this.form.thLangstrecke
              : curr.thLangstrecke,
            thInlandsflug: this.keysFromEditFields.includes('thInlandsflug')
              ? this.form.thInlandsflug
              : curr.thInlandsflug,
          };
          return acc;
        }, {});
        return params;
      } else return null;
    },
    onShow() {
      if (this.preexistingFormData != null) {
        this.form = { ...this.preexistingFormData };
      }
      if (this.changedFields) {
        this.editFields = this.changedFields.map(changedField =>
          this.fieldEditOptions.find(fieldOption => fieldOption.key === changedField)
        );
      }
    },
    async handleSubmit(hide) {
      const reisekuerzel = this.reisekuerzelToEdit;

      // wenn es Felder zu bearbeiten gibt, wo die Metadaten bearbeitet werden müssen
      if (this.editFields.some(field => !field.reiseterminValues)) {
        const formattedParams = this.formatParams();
        if (Object.values(formattedParams)?.length > 0) {
          this.isLoading = true;
          await this.$store
            .dispatch(EDIT_REISETERMIN_METADATA_MULTIPLE, { formattedParams, reisekuerzel })
            .then(res => {
              this.isLoading = false;
              this.toast('Erfolgreich bearbeitet.');
              this.$emit('reiseterminMetadataBearbeitet', res.data.result.value);
            });
        }
      }
      if (this.editFields.some(field => field.reiseterminValues)) {
        const formattedParams = this.formatParamsReiseterminValues();
        if (Object.values(formattedParams).length > 0) {
          this.isLoading = true;
          await this.$store
            .dispatch(BULK_EDIT_REISETERMIN_VALUES, { formattedParams, reisekuerzel })
            .then(res => {
              this.isLoading = false;
              this.toast('Erfolgreich bearbeitet.');
              this.$emit('reiseterminMetadataBearbeitet', res.data.result.value);
            });
        }
      }
      hide();
    },
    onHide() {
      this.form = {
        overnightOutbound: null,
        overnightInbound: null,
        ziellandAbflughaefenIataCodes: null,
        ziellandZielflughaefenIataCodes: null,
        ziellandAbflughafenPrio: null,
        ziellandZielflughafenPrio: null,
        hotelstartVorLandung: null,
        rueckflugNachHotelende: null,
        isKritischeDestination: null,
        kalkPax: null,
        erwartetePax: null,
        minPax: null,
        maxPax: null,
        durchfuehrbareMinPax: null,
        skrFlugangebot: null,
        transferszenarioHin: null,
        transferszenarioRueck: null,
        hotelnaechte: null,
        transferkostenHin: null,
        transferkostenRueck: null,
        transferkostenWaehrung: null,
        fruehesterAbflug: null,
        spaetesterAbflug: null,
        fruehesteAnkunft: null,
        spaetesteAnkunft: null,
        releasefrist: null,
        vorzeitigGarantiert: null,
        istZusatztermin: null,
        thLangstrecke: null,
        thInlandsflug: null,
        einkaufspreisVornachtDzProPerson: null,
        erwarteteTermindurchfuehrungsquote: null,
        restriktionenHinRollover: null,
        restriktionenRueckRollover: null,
      };
      this.editFields = [];
    },
    onAddReleasefrist() {
      const length = this.form.releasefrist?.length;
      if (!this.form.releasefrist) {
        this.form.releasefrist = [{ value: 0, id: 1 }];
        return;
      }
      this.form.releasefrist = [...this.form.releasefrist, { value: 0, id: length + 1 }];
    },
    onRemoveReleasefrist() {
      const length = this.form.releasefrist.length;
      this.form.releasefrist.pop();
    },
  },
};
</script>
<style scoped>
.plus-sign {
  height: 24px;
  width: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
:deep(.modal-header) {
  padding: 12px;
}
:deep(.modal-footer) {
  padding: 0.5rem;
}
</style>

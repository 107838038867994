<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
      <h3 class="card-title font-weight-bolder">{{ $t('COMMON.KOMMENTARE') }}</h3>
      <span><b-spinner v-if="isLoading" label="Spinning"></b-spinner></span>
    </div>
    <div class="card-body p-0">
      <div class="mb-2 overflow-auto">
        <perfect-scrollbar class="scroll">
          <div>
            <template v-if="comments.length !== 0">
              <div v-if="paginatedComments.length === 0" class="shadow-xs">
                <div class="d-flex align-items-start card-spacer-x py-4">
                  <div class="font-weight-bold text-muted">Keine Yield Kommentare vorhanden.</div>
                </div>
              </div>
              <TransitionGroup name="commentslist" tag="div">
                <div
                  v-for="comment in paginatedComments"
                  :key="'comment-' + comment.id"
                  class="shadow-xs comment-box"
                  :class="{ 'bg-warning-o-50': comment.id === currentlyEditingCommentObject }"
                >
                  <ReiseterminComment
                    :comment="comment"
                    :isAdmin="isAdmin"
                    :isLoading="isLoading"
                    :ownUserId="ownUserId"
                    @comment-delete="onClickCommentDelete"
                    @comment-edit="onClickCommentEdit"
                    @comment-reply="onClickCommentReply"
                    @comment-mark="onClickCommentMark"
                  ></ReiseterminComment>
                </div>
              </TransitionGroup>
            </template>
            <template v-else>
              <div class="shadow-xs">
                <div class="d-flex align-items-start card-spacer-x py-4">
                  <div class="d-flex flex-column flex-grow-1 flex-wrap mr-2">
                    <div class="d-flex flex-column">
                      <div class="font-weight-bold text-muted">
                        {{ $t('COMMON.EMPTYTEXT', { name: 'Kommentare' }) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </perfect-scrollbar>
        <b-pagination
          v-if="comments.length > perPage"
          class="shadow-xs p-2 w-100"
          align="fill"
          prev-text="<"
          next-text=">"
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="comments.length"
          :per-page="perPage"
        ></b-pagination>
      </div>
      <ReiseterminCommentAnswerBox
        ref="reiseterminCommentAnswerBox"
        :value.sync="commentText"
        :currentlyEditingCommentObject="currentlyEditingCommentObject"
        :currentlyEditingCommentType="currentlyEditingCommentType"
        :isLoading="isLoading"
        @comment-save="onClickSendComment"
        @comment-edit-cancel="onClickCancelComment"
        @comment-delete-my-comment="onClickDeleteMyComment"
      ></ReiseterminCommentAnswerBox>
    </div>
  </div>
</template>

<script>
import ReiseterminComment from '@/components/produkte/reisetermine/reisetermin-comment.vue';
import ReiseterminCommentAnswerBox from '@/components/produkte/reisetermine/reisetermin-comment-answer-box.vue';
import { parseISO, compareAsc } from 'date-fns';
import { mapGetters } from 'vuex';
export default {
  components: { ReiseterminComment, ReiseterminCommentAnswerBox },
  props: {
    comments: { type: Array },
    currentlyEditingCommentObject: { type: Object, default: null },
    currentlyEditingCommentType: { type: String, default: null },
    isAdmin: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    ownUserId: { type: String },
    doNotShowYieldComments: { type: Boolean },
    doNotShowPmComments: { type: Boolean },
  },
  data() {
    return {
      commentText: '',
      perPage: 5,
      currentPage: 1,
    };
  },
  watch: {
    comments: {
      immediate: true,
      handler: function (c) {
        this.currentPage = 1; // Changed because #1821
      },
    },
  },
  computed: {
    ...mapGetters(['getYieldTeamUsersIds', 'getPmTeamUsersIds']),
    paginatedComments() {
      const sortedComments = [...this.comments].sort((a, b) =>
        compareAsc(parseISO(b.createdDateUtc), parseISO(a.createdDateUtc))
      );

      let filteredComments = sortedComments;

      if (this.doNotShowYieldComments) {
        filteredComments = filteredComments.filter(
          comment => !this.getYieldTeamUsersIds.includes(comment.creator)
        );
      }
      if (this.doNotShowPmComments) {
        filteredComments = filteredComments.filter(
          comment => !this.getPmTeamUsersIds.includes(comment.creator)
        );
      }

      return filteredComments.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
  },
  methods: {
    scrollToAnswerBox() {
      this.$refs['reiseterminCommentAnswerBox'].$el.scrollIntoView({ block: 'end' });
    },
    onClickCommentMark(commentObject) {
      this.$emit('comment-mark', commentObject);
    },
    onClickCommentReply(commentObject) {
      this.$emit('comment-reply', commentObject);
      this.commentText = '';
      this.scrollToAnswerBox();
    },
    onClickCancelComment() {
      this.$emit('comment-edit-cancel');
      this.commentText = '';
    },
    onClickSendComment() {
      this.$emit('comment-save', this.commentText);
      this.commentText = '';
    },
    onClickDeleteMyComment() {
      this.commentText = '';
    },
    onClickCommentDelete(commentObject) {
      this.$emit('comment-edit-cancel');
      this.$emit('comment-delete', commentObject);
    },
    onClickCommentEdit(commentObject) {
      this.$emit('comment-edit', commentObject);
      this.commentText = commentObject.text;
      this.scrollToAnswerBox();
    },
  },
};
</script>
<style lang="scss" scoped>
.commentslist-move, /* apply transition to moving elements */
.commentslist-enter-active,
.commentslist-leave-active {
  transition: all 0.3s ease;
}

.commentslist-enter-from,
.commentslist-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.commentslist-leave-active {
  position: absolute;
}

.comment-box {
  min-height: 100px;
}
:deep(.comment-box p) {
  word-break: break-word;
}
</style>

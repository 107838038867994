<template>
  <b-sidebar
    id="sidebar-terminplanung"
    backdrop-variant="dark"
    backdrop
    shadow
    right
    width="max(50%,700px)"
    @shown="onShow"
    @hidden="onHidden"
  >
    <template #header="{ hide }">
      <div class="d-flex justify-content-between align-items-center w-100 ml-2">
        <div>
          <i class="fa-chevron-left fas icon-xl mr-2 navigationIcons" @click="goUpOneRow"></i>
          <i class="fa-chevron-right fas icon-xl mr-2 navigationIcons" @click="goDownOneRow"></i>
        </div>
        <h4>
          <b>{{ reiseterminkuerzelFormatted }}</b>
        </h4>
        <div>
          <b-dropdown right text="..." no-caret size="sm" class="mr-3">
            <template #button-content> <div class="dots-dropdown">...</div></template>
            <b-dropdown-item
              size="sm"
              @click="onButtonStatusBearbeiten(hide)"
              v-if="
                ['Vorgeschlagen', 'Verworfen', 'CmBestaetigt', 'CmAngefragt'].includes(
                  activeReisetermin?.state
                )
              "
              >Status bearbeiten</b-dropdown-item
            >
            <b-dropdown-item size="sm" @click="openModalWithData" v-b-modal.modalAufgabeEdit
              >Aufgabe erstellen</b-dropdown-item
            >
            <b-dropdown-item
              size="sm"
              @click="onClickDeleteReisetermin(hide)"
              variant="danger"
              v-if="
                activeReisetermin?.state !== 'Anlagebereit' &&
                activeReisetermin?.reiseterminSyncState === 'NotAvailableInBlank'
              "
              >Reisetermin löschen</b-dropdown-item
            >
          </b-dropdown>
          <b-button
            class="mr-4"
            size="sm"
            @click="saveChanges"
            :variant="disableSpeichernButton ? 'secondary' : 'success'"
            :disabled="disableSpeichernButton"
            >Speichern</b-button
          >
          <i class="fas fa-times m-3 navigationIcons" @click="hide"></i>
        </div>
      </div>
    </template>
    <b-overlay :show="isLoading">
      <div class="px-8 py-4" v-if="activeMetadata" style="width: 100%">
        <CollapsibleWithItemsEditable
          header="Reisedaten"
          v-model="reisedaten"
          :isCollapsedOnMount="true"
        ></CollapsibleWithItemsEditable>
        <CollapsibleWithItemsEditable class="mt-10" header="Reisetermindaten" v-model="reisetermindaten" />
        <CollapsibleWithItemsEditable class="mt-10" header="Flugdaten" v-model="flugdaten" />
        <div class="mt-12 mb-2">
          <span class="d-flex align-items-center">
            <h5
              role="button"
              @click="isInlandsfluegeCollapsed = !isInlandsfluegeCollapsed"
              :aria-expanded="isInlandsfluegeCollapsed ? 'true' : 'false'"
              aria-controls="collapse-3"
              class="user-select-none mb-0"
            >
              <b-icon
                v-if="!isInlandsfluegeCollapsed"
                :scale="0.75"
                icon="chevron-right"
                aria-hidden="true"
              ></b-icon>
              <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
              Inlandsflüge
            </h5>
            <span
              v-if="!localInlandsflugExists"
              class="ml-3 plus-sign bg-primary"
              @click="addLocalInlandsflug"
            >
              +
            </span>
          </span>
          <hr class="w-75 ml-0" />
          <b-collapse
            id="collapse-3"
            :class="isInlandsfluegeCollapsed ? null : 'collapsed'"
            v-model="isInlandsfluegeCollapsed"
          >
            <div v-if="inlandsfluege?.length > 0">
              <div v-for="(inlandsflug, i) in inlandsfluege" :key="inlandsflug.id" class="mb-6">
                <h6 class="mb-4 ml-2">
                  {{ i + 1 + '. Inlandsflug' }}
                  <i
                    v-if="inlandsflug.matchingSeasonInlandsflug === null"
                    class="fas fa-asterisk text-warning icon-sm"
                    style="align-self: start; position: absolute"
                    v-b-tooltip.hover
                    title="Kein passender Saison-Inlandsflug gefunden."
                  >
                  </i>
                  <span class="ml-4" @click="deleteInlandsflug(inlandsflug.id)" style="cursor: pointer">
                    <i class="fas fa-trash" style="color: black"></i
                  ></span>
                </h6>
                <b-list-group horizontal class="bv-example-row">
                  <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
                    <h6 class="m-0 leftLabel">Tag / Verkehrstag / Datum</h6>
                  </b-list-group-item>
                  <b-list-group-item
                    class="col-6 p-2 pl-4"
                    style="display: grid; grid-template-columns: repeat(3, 1fr)"
                  >
                    <b-form-input
                      v-model="inlandsflug.tag"
                      :disabled="!inlandsflug?.isOnlyLocal"
                      class="border-0 pl-3 m-0 leftLabel"
                      type="number"
                      step="1"
                      min="0.00"
                      max="1000.00"
                      :number="true"
                      placeholder="-"
                    ></b-form-input>
                    <div class="leftLabel p-2">
                      {{ getVerkehrstagOfInlandsflug(activeReisetermin, inlandsflug) }}
                    </div>
                    <div class="leftLabel p-2">
                      {{ getDateOfIlandsflug(activeReisetermin, inlandsflug) }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <b-list-group horizontal class="bv-example-row">
                  <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
                    <h6 class="m-0 leftLabel">
                      Einkauf
                      <i
                        v-if="
                          inlandsflug.matchingSeasonInlandsflug?.einkauf !==
                            inlandsflug.valuesBeforeEdit?.einkauf &&
                          !inlandsflug?.isOnlyLocal &&
                          inlandsflug.matchingSeasonInlandsflug !== null
                        "
                        class="fas fa-asterisk text-warning icon-sm"
                        style="align-self: start; position: absolute"
                        v-b-tooltip.hover
                        :title="`Saisonwert: ${inlandsflug.matchingSeasonInlandsflug?.einkauf.toUpperCase()}`"
                      >
                      </i>
                    </h6>
                  </b-list-group-item>
                  <b-list-group-item class="col-6 d-flex align-items-center">
                    <b-form-select size="sm" v-model="inlandsflug.einkauf">
                      <b-form-select-option :value="null">-</b-form-select-option>
                      <b-form-select-option :value="'Skr'">SKR</b-form-select-option>
                      <b-form-select-option :value="'Dmc'">DMC</b-form-select-option>
                    </b-form-select>
                  </b-list-group-item>
                </b-list-group>
                <b-list-group horizontal class="bv-example-row">
                  <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
                    <h6 class="m-0 leftLabel">Abflughafen</h6>
                  </b-list-group-item>
                  <b-list-group-item class="col-6 d-flex align-items-center">
                    <AirportsSucheMultiselect
                      class="w-100"
                      :disabled="!inlandsflug?.isOnlyLocal"
                      isSidebarTerminplanung
                      v-model="inlandsflug.abflughafenIataCode"
                      :isMultipleAllowed="true"
                      :openDirection="'top'"
                      :filterCountry="activeReisetermin?.reiseterminkuerzel?.substring(0, 2)"
                      id="abflughafen"
                    ></AirportsSucheMultiselect>
                  </b-list-group-item>
                </b-list-group>
                <b-list-group horizontal class="bv-example-row">
                  <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
                    <h6 class="m-0 leftLabel">
                      Zielflughafen
                      <i
                        v-if="
                          inlandsflug.matchingSeasonInlandsflug?.zielflughafenIataCode !==
                            inlandsflug.valuesBeforeEdit?.zielflughafenIataCode &&
                          !inlandsflug?.isOnlyLocal &&
                          inlandsflug.matchingSeasonInlandsflug !== null
                        "
                        class="fas fa-asterisk text-warning icon-sm"
                        style="align-self: start; position: absolute"
                        v-b-tooltip.hover
                        :title="`Saisonwert: ${inlandsflug.matchingSeasonInlandsflug?.zielflughafenIataCode}`"
                      >
                      </i>
                    </h6>
                  </b-list-group-item>
                  <b-list-group-item class="col-6 d-flex align-items-center">
                    <AirportsSucheMultiselect
                      class="w-100"
                      isSidebarTerminplanung
                      v-model="inlandsflug.zielflughafenIataCode"
                      :isMultipleAllowed="true"
                      :openDirection="'top'"
                      :filterCountry="activeReisetermin?.reiseterminkuerzel?.substring(0, 2)"
                      id="abflughafen"
                    ></AirportsSucheMultiselect>
                  </b-list-group-item>
                </b-list-group>
                <b-list-group horizontal class="bv-example-row">
                  <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
                    <h6 class="m-0 leftLabel">
                      Frühester Abflug
                      <i
                        v-if="
                          inlandsflug.matchingSeasonInlandsflug?.fruehesterAbflug?.substring(0, 5) !==
                            inlandsflug.valuesBeforeEdit?.fruehesterAbflug?.substring(0, 5) &&
                          !inlandsflug?.isOnlyLocal &&
                          inlandsflug.matchingSeasonInlandsflug !== null
                        "
                        class="fas fa-asterisk text-warning icon-sm"
                        style="align-self: start; position: absolute"
                        v-b-tooltip.hover
                        :title="`Saisonwert: ${inlandsflug.matchingSeasonInlandsflug?.fruehesterAbflug?.substring(
                          0,
                          5
                        )}`"
                      >
                      </i>
                    </h6>
                  </b-list-group-item>
                  <b-list-group-item class="col-6 d-flex align-items-center">
                    <date-picker
                      class="w-100"
                      v-model="inlandsflug.fruehesterAbflug"
                      type="time"
                      input-class="form-control"
                      format="HH:mm"
                      value-type="format"
                      ref="datepicker"
                    ></date-picker>
                  </b-list-group-item>
                </b-list-group>
                <b-list-group horizontal class="bv-example-row">
                  <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
                    <h6 class="m-0 leftLabel">
                      Späteste Ankunft
                      <i
                        v-if="
                          inlandsflug.matchingSeasonInlandsflug?.spaetesteAnkunft?.substring(0, 5) !==
                            inlandsflug.valuesBeforeEdit?.spaetesteAnkunft?.substring(0, 5) &&
                          !inlandsflug?.isOnlyLocal &&
                          inlandsflug.matchingSeasonInlandsflug !== null
                        "
                        class="fas fa-asterisk text-warning icon-sm"
                        style="align-self: start; position: absolute"
                        v-b-tooltip.hover
                        :title="`Saisonwert: ${inlandsflug.matchingSeasonInlandsflug?.spaetesteAnkunft?.substring(
                          0,
                          5
                        )}`"
                      >
                      </i>
                    </h6>
                  </b-list-group-item>
                  <b-list-group-item class="col-6 d-flex align-items-center">
                    <date-picker
                      class="w-100"
                      v-model="inlandsflug.spaetesteAnkunft"
                      type="time"
                      input-class="form-control"
                      format="HH:mm"
                      value-type="format"
                      ref="datepicker"
                    ></date-picker>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </b-collapse>
        </div>
        <div v-if="aufgabenTerminplanung?.length > 0" class="mt-12">
          <h5
            role="button"
            @click="isAufgabenCollapsed = !isAufgabenCollapsed"
            :aria-expanded="isAufgabenCollapsed ? 'true' : 'false'"
            aria-controls="collapse-5"
            class="mb-2 user-select-none"
          >
            <b-icon
              v-if="!isAufgabenCollapsed"
              :scale="0.75"
              icon="chevron-right"
              aria-hidden="true"
            ></b-icon>
            <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
            Aufgaben
          </h5>
          <hr class="w-75 ml-0" />
          <b-collapse
            id="collapse-5"
            :class="isAufgabenCollapsed ? null : 'collapsed'"
            v-model="isAufgabenCollapsed"
          >
            <AufgabenWidget
              :filter="'sidebar-terminplanung'"
              :ansicht="'sidebar-terminplanung'"
              :aufgaben="aufgabenTerminplanung || []"
            ></AufgabenWidget>
          </b-collapse>
        </div>
        <div class="mt-12">
          <h5 class="mt-3 d-flex" style="flex-direction: column; gap: 16px">
            <a :href="'#/reisetermine/' + activeReisetermin.id" target="_blank">
              <b-icon icon="box-arrow-up-right"></b-icon> Reisetermin Detailansicht</a
            >

            <a
              :href="
                '#/reisen/' +
                activeReisetermin.reiseterminkuerzel?.substring(0, 6) +
                '/reisestammdaten?reiseterminAbreisedatum=' +
                activeReisetermin.abreisedatum.substring(0, 10)
              "
              target="_blank"
            >
              <b-icon icon="box-arrow-up-right"></b-icon> Reisestammdaten</a
            >
          </h5>
        </div>
        <ReiseterminCardCommentBase
          class="mt-8"
          v-if="activeComments"
          :reisekuerzel="activeReisetermin.reise.reisekuerzel"
          :reiseterminId="activeReisetermin.id"
          :startdatum="activeReisetermin.startdatum"
          :initalComments="activeReisetermin.comments"
        />
        <b-skeleton v-else width="100%" height="413px"></b-skeleton>
      </div>
    </b-overlay>
  </b-sidebar>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import { format, add, getISODay } from 'date-fns';
import AirportsSucheMultiselect from '@/components/common/airports-suche-multiselect.vue';
import { transferszenarioOptions, getBlankSyncState } from '@/core/common/helpers/utils';
import { EDIT_REISETERMIN_METADATA_MULTIPLE } from '@/core/produkte/reisetermine/stores/reiseterminplanung.module';
import apiService from '@/core/common/services/api.service';
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';
import { grundOptions, verworfenGrundToLabel } from '@/core/common/helpers/utils';
import CollapsibleWithItemsEditable from '@/components/common/collapsible-with-items-editable.vue';
import ReiseterminCardCommentBase from '@/components/produkte/reisetermine/reisetermin-card-comment-base.vue';

export default {
  data() {
    return {
      reisedaten: [],
      reisetermindaten: [],
      flugdaten: [],
      grundOptions,
      isReisedatenCollapsed: true,
      activeComments: true,
      isFlugdatenCollapsed: false,
      isAufgabenCollapsed: true,
      isInlandsfluegeCollapsed: false,
      isReisetermindatenCollapsed: false,
      isLoading: false,
      vorzeitigGarantiert: null,
      istZusatztermin: null,
      thLangstrecke: null,
      thInlandsflug: null,
      sortedInlandsfluege: null,

      inlandsfluege: null,
      transferszenarioOptions,
      wochentagOptions: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
      inlandsfluegeConfig: [
        { label: 'Tag', value: 'tag' },
        { label: 'Zielflughafen HIN', value: 'zielflughafenIataCode' },
        { label: 'Abflughafen RÜCK', value: 'abflughafenIataCode' },
        { label: 'Einkauf', value: 'einkauf' },
        { label: 'Frühester Abflug', value: 'fruehesterAbflug' },
        { label: 'Späteste Ankunft', value: 'spaetesteAnkunft' },
      ],
      prefixedVerlinkungen: [],
    };
  },
  props: {
    activeReisetermin: { type: Object },
  },
  components: {
    AirportsSucheMultiselect,
    DatePicker,
    AufgabenWidget,
    CollapsibleWithItemsEditable,
    ReiseterminCardCommentBase,
  },
  watch: {
    activeReisetermin: {
      immediate: true,
      handler(v) {
        if (v !== null) {
          this.initInlandsfluegeAndReiseterminData();
          this.setReisedaten();
          this.setReisetermindaten();
          this.setFlugdaten();
        }
      },
    },
  },
  mounted() {
    if (this.activeReisetermin) {
      this.setReisedaten();
      this.setReisetermindaten();
      this.setFlugdaten();
    }
  },
  computed: {
    aufgabenTerminplanung() {
      if (!this.activeReisetermin || this.activeReisetermin.aufgaben?.length === 0) {
        return null;
      }
      const kategorienToShow = [
        'TerminAbsagen',
        'OptionsfristErreicht',
        'GruppeneinkaufPruefen',
        'AnlageHotelstamm',
        'AnlageFlugstamm',
      ];

      return this.activeReisetermin.aufgaben.filter(aufgabe => kategorienToShow.includes(aufgabe.kategorie));
    },
    gueltigeSaisonInlandsfluege() {
      if (this.activeReisetermin?.gueltigeSaison?.inlandsfluege) {
        return this.activeReisetermin.gueltigeSaison.inlandsfluege;
      } else return null;
    },
    noInlandsflugChanges() {
      if (this.activeMetadata?.inlandsfluege?.length > 0) {
        return this.inlandsfluege
          .filter(inlf => !inlf?.isOnlyLocal)
          .every((inlandsflug, i) => {
            return (
              inlandsflug.valuesBeforeEdit.tag === inlandsflug.tag &&
              inlandsflug.valuesBeforeEdit.einkauf === inlandsflug.einkauf &&
              inlandsflug.valuesBeforeEdit.fruehesterAbflug?.substring(0, 5) ===
                inlandsflug.fruehesterAbflug?.substring(0, 5) &&
              inlandsflug.valuesBeforeEdit.spaetesteAnkunft?.substring(0, 5) ===
                inlandsflug.spaetesteAnkunft?.substring(0, 5) &&
              JSON.stringify(inlandsflug.valuesBeforeEdit.zielflughafenIataCode)
                .replaceAll('[', '')
                .replaceAll(']', '') ===
                JSON.stringify(inlandsflug.zielflughafenIataCode).replaceAll('[', '').replaceAll(']', '') &&
              JSON.stringify(inlandsflug.valuesBeforeEdit.abflughafenIataCode)
                .replaceAll('[', '')
                .replaceAll(']', '') ===
                JSON.stringify(inlandsflug.abflughafenIataCode).replaceAll('[', '').replaceAll(']', '')
            );
          });
      } else return true;
    },
    inlandsfluegeChanged() {
      return this.inlandsfluege.filter((inlandsflug, i) => {
        if (inlandsflug?.isOnlyLocal) return false;
        if (
          inlandsflug.valuesBeforeEdit.tag === inlandsflug.tag &&
          inlandsflug.valuesBeforeEdit.einkauf === inlandsflug.einkauf &&
          inlandsflug.valuesBeforeEdit.fruehesterAbflug?.substring(0, 5) ===
            inlandsflug.fruehesterAbflug?.substring(0, 5) &&
          inlandsflug.valuesBeforeEdit.spaetesteAnkunft?.substring(0, 5) ===
            inlandsflug.spaetesteAnkunft?.substring(0, 5) &&
          JSON.stringify(inlandsflug.valuesBeforeEdit.zielflughafenIataCode)
            .replaceAll('[', '')
            .replaceAll(']', '') ===
            JSON.stringify(inlandsflug.zielflughafenIataCode).replaceAll('[', '').replaceAll(']', '') &&
          JSON.stringify(inlandsflug.valuesBeforeEdit.abflughafenIataCode)
            .replaceAll('[', '')
            .replaceAll(']', '') ===
            JSON.stringify(inlandsflug.abflughafenIataCode).replaceAll('[', '').replaceAll(']', '')
        ) {
          return false;
        } else return true;
      });
    },
    noReiseterminDataChanges() {
      return (
        this.reisetermindaten
          .filter(item => item.editable)
          .every(
            item =>
              item.value === item.valueEdited ||
              JSON.stringify(item.value)?.replaceAll('[', '').replaceAll(']', '') ===
                JSON.stringify(item.valueEdited)?.replaceAll('[', '').replaceAll(']', '')
          ) &&
        this.flugdaten
          .filter(item => (!Array.isArray(item.value) && item.editable) || item.editable?.[0])
          .filter(item => item.label?.[0] === 'TH Langstrecke')
          .every(
            item =>
              item.value === item.valueEdited ||
              JSON.stringify(item.value)?.replaceAll('[', '').replaceAll(']', '') ===
                JSON.stringify(item.valueEdited)?.replaceAll('[', '').replaceAll(']', '')
          )
      );
    },
    localInlandsflug() {
      if (this.localInlandsflugExists) {
        return this.inlandsfluege.find(inlf => inlf?.isOnlyLocal);
      } else return null;
    },
    noReadyLocalInlandsflug() {
      if (this.localInlandsflug) {
        const allFieldsSet =
          this.localInlandsflug.tag !== null &&
          this.localInlandsflug.einkauf !== null &&
          this.localInlandsflug.abflughafenIataCode !== null &&
          this.localInlandsflug.abflughafenIataCode?.length > 0 &&
          this.localInlandsflug.zielflughafenIataCode !== null &&
          this.localInlandsflug.zielflughafenIataCode?.length > 0 &&
          this.localInlandsflug.fruehesteAnkunft !== null &&
          this.localInlandsflug.spaetesteAnkunft !== null;
        return !allFieldsSet;
      }
      return true;
    },
    disableSpeichernButton() {
      return (
        this.noMetadataChanges &&
        this.noReiseterminDataChanges &&
        this.noInlandsflugChanges &&
        this.noReadyLocalInlandsflug
      );
    },
    noMetadataChanges() {
      return (
        this.reisedaten
          .filter(item => (!Array.isArray(item.value) && item.editable) || item.editable?.[0])
          .every(
            item =>
              item.value === item.valueEdited ||
              JSON.stringify(item.value)?.replaceAll('[', '').replaceAll(']', '') ===
                JSON.stringify(item.valueEdited)?.replaceAll('[', '').replaceAll(']', '')
          ) &&
        this.flugdaten
          .filter(
            item =>
              ((!Array.isArray(item.value) && item.editable) || item.editable?.[0]) &&
              item.label?.[0] !== 'TH Langstrecke'
          )
          .every(
            item =>
              item.value === item.valueEdited ||
              JSON.stringify(item.value)?.replaceAll('[', '').replaceAll(']', '') ===
                JSON.stringify(item.valueEdited)?.replaceAll('[', '').replaceAll(']', '')
          )
      );
    },
    reiseterminkuerzelFormatted() {
      if (this.activeReisetermin) {
        const rtKuerzel = this.activeReisetermin.reiseterminkuerzel;
        const startdatum = this.activeReisetermin.startdatum;
        return rtKuerzel.substring(0, 6) + '  ' + format(new Date(startdatum), 'dd.MM.yyyy');
      } else return '';
    },
    activeMetadata() {
      if (this.activeReisetermin) {
        return this.activeReisetermin.metadata;
      } else return null;
    },
    localInlandsflugExists() {
      return this.inlandsfluege?.some(inlandsflug => inlandsflug?.isOnlyLocal == true);
    },
  },
  methods: {
    addLocalInlandsflug() {
      this.isInlandsfluegeCollapsed = true;
      if (!this.localInlandsflugExists) {
        this.inlandsfluege.unshift({
          isOnlyLocal: true,
          id: 999999,
          abflughafenIataCode: null,
          zielflughafenIataCode: null,
          tag: null,
          einkauf: null,
          fruehesterAbflug: null,
          spaetesteAnkunft: null,
          valuesBeforeEdit: {
            abflughafenIataCode: null,
            zielflughafenIataCode: null,
            tag: null,
            einkauf: null,
            fruehesterAbflug: null,
            spaetesteAnkunft: null,
          },
        });
      }
    },
    async deleteInlandsflug(id) {
      // wenn es nur ein lokaler ist, entferne ihn
      if (id === 999999) {
        this.inlandsfluege = this.inlandsfluege.filter(inlf => inlf.id !== id);
        return;
      }

      const { startdatum, reise } = this.activeReisetermin;

      const confirmDeletion = await this.$bvModal.msgBoxConfirm(
        'Möchtest du den Inlandsflug wirklich löschen?',
        {
          centered: true,
          okTitle: 'Löschen',
          okVariant: 'danger',
          cancelTitle: 'Abbrechen',
        }
      );
      if (confirmDeletion) {
        this.isLoading = true;

        apiService
          .delete(
            `Reise/${reise.reisekuerzel}/metadata/${startdatum}/inlandsflug/reisetermin?inlandsflugId=${id}`
          )
          .then(() => {
            this.toast('Erfolgreich gelöscht');
            this.inlandsfluege = this.inlandsfluege.filter(inlf => inlf.id !== id);
            this.$emit('deletedInlandsflug', {
              reiseterminkuerzel: this.activeReisetermin.reiseterminkuerzel,
              id,
            });
          })
          .finally(() => (this.isLoading = false));
      }
    },
    findMatchingSeasonInlandsflug(tag, abflughafen) {
      if (this.gueltigeSaisonInlandsfluege) {
        const matchingInlandflug = this.gueltigeSaisonInlandsfluege.find(
          inlf => inlf.tag === tag && inlf.abflughafenIataCode === abflughafen
        );
        return matchingInlandflug ?? null;
      } else return null;
    },
    formatParamsMetadata() {
      const fieldsToSkipFlugdaten = ['TH Langstrecke', 'Verkehrstag Hin'];
      return {
        ...this.reisedaten.reduce((acc, curr) => {
          if (!curr.paramLabel || fieldsToSkipFlugdaten.includes(curr.label?.[0])) return acc;
          if (curr.paramLabel === 'releasefrist') {
            acc[curr.paramLabel] = [...curr.valueEdited];
          } else if (Array.isArray(curr.paramLabel)) {
            acc[curr.paramLabel[0]] = curr.valueEdited[0];
            acc[curr.paramLabel[1]] = curr.valueEdited[1];
            return acc;
          }
          acc[curr.paramLabel] = curr.valueEdited;
          return acc;
        }, {}),
        ...this.flugdaten.reduce((acc, curr) => {
          if (!curr.paramLabel || fieldsToSkipFlugdaten.includes(curr.label?.[0])) return acc;
          if (Array.isArray(curr.paramLabel)) {
            acc[curr.paramLabel[0]] = curr.valueEdited[0];
            acc[curr.paramLabel[1]] = curr.valueEdited[1];
            return acc;
          } else if (
            curr.paramLabel === 'restriktionenHinRollover' ||
            curr.paramLabel === 'restriktionenRueckRollover'
          ) {
            acc[curr.paramLabel] = +curr.valueEdited;
            return acc;
          }
          acc[curr.paramLabel] = curr.valueEdited;
          return acc;
        }, {}),
        aufpreisBeiVerspaetetenPassdaten: this.activeMetadata.aufpreisBeiVerspaetetenPassdaten,
        passdatenFrist: this.activeMetadata.passdatenFrist,
      };
    },
    setReisedaten() {
      this.reisedaten = [
        {
          label: 'Hotelnächte',
          value: this.activeReisetermin.metadata?.hotelnaechte,
          valueEdited: this.activeReisetermin.metadata?.hotelnaechte,
          valueDiffersSeason:
            this.activeReisetermin.metadata?.hotelnaechte !==
            this.activeReisetermin.gueltigeSaison.hotelnaechte,
          valueSeason: this.activeReisetermin.gueltigeSaison.hotelnaechte,
          renderer: null,
          editable: true,
          editType: 'number',
          paramLabel: 'hotelnaechte',
        },
        {
          label: 'Kalk Pax',
          value: this.activeReisetermin.metadata?.kalkPax,
          valueEdited: this.activeReisetermin.metadata?.kalkPax,
          valueDiffersSeason:
            this.activeReisetermin.metadata?.kalkPax !== this.activeReisetermin.gueltigeSaison.kalkPax,
          valueSeason: this.activeReisetermin.gueltigeSaison.kalkPax,
          renderer: null,
          editable: true,
          editType: 'number',
          paramLabel: 'kalkPax',
        },
        {
          label: 'Erwartete Pax',
          value: this.activeReisetermin.metadata?.erwartetePax,
          valueEdited: this.activeReisetermin.metadata?.erwartetePax,
          valueDiffersSeason:
            this.activeReisetermin.metadata?.erwartetePax !==
            this.activeReisetermin.gueltigeSaison.erwartetePax,
          valueSeason: this.activeReisetermin.gueltigeSaison.erwartetePax,
          renderer: null,
          editable: true,
          editType: 'number',
          paramLabel: 'erwartetePax',
        },
        {
          label: 'Erw. Termindurchführungsquote',
          value: this.activeReisetermin.metadata?.erwarteteTermindurchfuehrungsquote,
          valueEdited: this.activeReisetermin.metadata?.erwarteteTermindurchfuehrungsquote,
          valueDiffersSeason:
            this.activeReisetermin.metadata?.erwarteteTermindurchfuehrungsquote !==
            this.activeReisetermin.gueltigeSaison.erwarteteTermindurchfuehrungsquote,
          valueSeason: this.activeReisetermin.gueltigeSaison.erwarteteTermindurchfuehrungsquote,

          renderer: null,
          editable: true,
          editType: 'number',
          append: '%',
          paramLabel: 'erwarteteTermindurchfuehrungsquote',
        },
        {
          label: 'EK kalk. Vornacht DZ p.P.',
          value: this.activeReisetermin.metadata?.einkaufspreisVornachtDzProPerson,
          valueEdited: this.activeReisetermin.metadata?.einkaufspreisVornachtDzProPerson,
          valueDiffersSeason:
            this.activeReisetermin.metadata?.einkaufspreisVornachtDzProPerson !==
            this.activeReisetermin.gueltigeSaison.einkaufspreisVornachtDzProPerson,
          valueSeason: this.activeReisetermin.gueltigeSaison.einkaufspreisVornachtDzProPerson,

          renderer: null,
          editable: true,
          editType: 'number',
          append: '€',
          paramLabel: 'einkaufspreisVornachtDzProPerson',
        },
        {
          label: 'Min Pax',
          value: this.activeReisetermin.metadata?.minPax,
          valueEdited: this.activeReisetermin.metadata?.minPax,
          valueDiffersSeason:
            this.activeReisetermin.metadata?.minPax !== this.activeReisetermin.gueltigeSaison.minPax,
          valueSeason: this.activeReisetermin.gueltigeSaison.minPax,
          renderer: null,
          editable: true,
          editType: 'number',
          paramLabel: 'minPax',
        },
        {
          label: 'Max Pax',
          value: this.activeReisetermin.metadata?.maxPax,
          valueEdited: this.activeReisetermin.metadata?.maxPax,
          valueDiffersSeason:
            this.activeReisetermin.metadata?.maxPax !== this.activeReisetermin.gueltigeSaison.maxPax,
          valueSeason: this.activeReisetermin.gueltigeSaison.maxPax,
          renderer: null,
          editable: true,
          editType: 'number',
          paramLabel: 'maxPax',
        },
        {
          label: 'Durchführbare Min Pax',
          value: this.activeReisetermin.metadata?.durchfuehrbareMinPax,
          valueEdited: this.activeReisetermin.metadata?.durchfuehrbareMinPax,
          valueDiffersSeason:
            this.activeReisetermin.metadata?.durchfuehrbareMinPax !==
            this.activeReisetermin.gueltigeSaison.durchfuehrbareMinPax,
          valueSeason: this.activeReisetermin.gueltigeSaison.durchfuehrbareMinPax,
          renderer: null,
          editable: true,
          editType: 'number',
          paramLabel: 'durchfuehrbareMinPax',
        },
        {
          label: 'Kritische Destination',
          value: !!this.activeReisetermin.metadata?.isKritischeDestination,
          valueEdited: !!this.activeReisetermin.metadata?.isKritischeDestination,
          valueDiffersSeason:
            !!this.activeReisetermin.metadata?.isKritischeDestination !==
            !!this.activeReisetermin.gueltigeSaison.isKritischeDestination,
          valueSeason: !!this.activeReisetermin.gueltigeSaison.isKritischeDestination,
          renderer: null,
          editable: true,
          editType: 'checkbox',
          paramLabel: 'isKritischeDestination',
        },
        {
          label: ['Transferszenario Hin', 'Rück'],
          value: [
            this.activeReisetermin.metadata?.transferszenarioHin,
            this.activeReisetermin.metadata?.transferszenarioRueck,
          ],
          valueEdited: [
            this.activeReisetermin.metadata?.transferszenarioHin,
            this.activeReisetermin.metadata?.transferszenarioRueck,
          ],
          valueDiffersSeason: [
            this.activeReisetermin.metadata?.transferszenarioHin !==
              this.activeReisetermin.gueltigeSaison.transferszenarioHin,
            this.activeReisetermin.metadata?.transferszenarioRueck !==
              this.activeReisetermin.gueltigeSaison.transferszenarioRueck,
          ],
          valueSeason: [
            this.activeReisetermin.gueltigeSaison.transferszenarioHin?.substring(0, 2),
            this.activeReisetermin.gueltigeSaison.transferszenarioRueck?.substring(0, 2),
          ],
          renderer: null,
          editable: [true, true],
          editType: ['select', 'select'],
          options: transferszenarioOptions,
          split: true,
          paramLabel: ['transferszenarioHin', 'transferszenarioRueck'],
        },
        {
          label: ['Transferkosten Hin', 'Rück'],
          value: [
            this.activeReisetermin.metadata?.transferkostenHin,
            this.activeReisetermin.metadata?.transferkostenRueck,
          ],
          valueEdited: [
            this.activeReisetermin.metadata?.transferkostenHin,
            this.activeReisetermin.metadata?.transferkostenRueck,
          ],
          valueDiffersSeason: [
            this.activeReisetermin.metadata?.transferkostenHin !==
              this.activeReisetermin.gueltigeSaison.transferkostenHin,
            this.activeReisetermin.metadata?.transferkostenRueck !==
              this.activeReisetermin.gueltigeSaison.transferkostenRueck,
          ],
          valueSeason: [
            this.activeReisetermin.gueltigeSaison.transferkostenHin,
            this.activeReisetermin.gueltigeSaison.transferkostenRueck,
          ],
          renderer: null,
          editable: [true, true],
          editType: ['number', 'number'],
          split: true,
          append: [
            this.activeReisetermin.metadata?.transferkostenWaehrung ?? null,
            this.activeReisetermin.metadata?.transferkostenWaehrung ?? null,
          ],
          paramLabel: ['transferkostenHin', 'transferkostenRueck'],
        },
        {
          label: 'Releasefrist',
          value: [...this.activeReisetermin.metadata?.releasefrist],
          valueEdited: [...this.activeReisetermin.metadata?.releasefrist],
          valueDiffersSeason: this.activeReisetermin.metadata?.releasefrist.map(
            (releasefrist, i) => releasefrist !== this.activeReisetermin.gueltigeSaison?.releasefrist[i]
          ),
          valueSeason: this.activeReisetermin.gueltigeSaison?.releasefrist,
          renderer: null,
          editable: Array(this.activeReisetermin.metadata?.releasefrist.length).fill(true),
          split: false,
          splitN: true,
          paramLabel: 'releasefrist',
        },
      ];
    },
    setReisetermindaten() {
      this.reisetermindaten = [
        {
          label: 'Vorzeitig garantiert',
          value: this.activeReisetermin.vorzeitigGarantiert,
          valueEdited: this.activeReisetermin.vorzeitigGarantiert,
          renderer: null,
          editable: true,
          editType: 'checkbox',
          paramLabel: 'vorzeitigGarantiert',
        },
        {
          label: 'Zusatztermin',
          value: this.activeReisetermin.istZusatztermin,
          valueEdited: this.activeReisetermin.istZusatztermin,
          renderer: null,
          editable: true,
          editType: 'checkbox',
          paramLabel: 'istZusatztermin',
        },
        {
          label: 'In Blank angelegt',
          value: this.getBlankSyncState(this.activeReisetermin?.reiseterminSyncState),
          valueEdited: this.getBlankSyncState(this.activeReisetermin?.reiseterminSyncState),
          renderer:
            this.getBlankSyncState(this.activeReisetermin?.reiseterminSyncState) === 'Angelegt'
              ? "<i class='fas fa-check text-success'/>"
              : this.getBlankSyncState(this.activeReisetermin?.reiseterminSyncState) === 'Nicht angelegt'
              ? "<i class='ml-1 fas fa-times text-danger'/>"
              : '-',
          editable: false,
        },
        {
          label: 'Verworfen Grund',
          value: verworfenGrundToLabel[this.activeReisetermin?.verworfenData?.grund] ?? '-',
          valueEdited: verworfenGrundToLabel[this.activeReisetermin?.verworfenData?.grund] ?? '-',
          notShow: this.activeReisetermin.state !== 'Verworfen',
          renderer: null,
          editable: false,
          paramLabel: 'verworfenGrund',
        },
        {
          label: 'Verworfen Kommentar',
          value: this.activeReisetermin?.verworfenData?.kommentar ?? '-',
          valueEdited: this.activeReisetermin?.verworfenData?.kommentar ?? '-',
          notShow: this.activeReisetermin.state !== 'Verworfen',
          renderer: null,
          editable: false,
          paramLabel: 'verworfenKommentar',
        },
      ];
    },
    setFlugdaten() {
      this.flugdaten = [
        {
          label: 'SKR Flugangebot',
          value: this.activeMetadata.skrFlugangebot,
          valueEdited: this.activeMetadata.skrFlugangebot,
          valueDiffersSeason:
            this.activeMetadata?.skrFlugangebot !== this.activeReisetermin.gueltigeSaison.skrFlugangebot,
          valueSeason: this.activeReisetermin.gueltigeSaison.skrFlugangebot,
          renderer: null,
          editable: true,
          editType: 'number',
          paramLabel: 'skrFlugangebot',
          append: '€',
        },
        {
          label: ['Zielland Zielflughäfen', 'Prio'],
          value: [
            this.activeMetadata?.ziellandZielflughaefen.map(fh => fh.flughafenIataCode),
            this.activeMetadata?.ziellandZielflughaefen.find(fh => fh.isPrio)?.flughafenIataCode,
          ],
          valueEdited: [
            this.activeMetadata?.ziellandZielflughaefen.map(fh => fh.flughafenIataCode),
            this.activeMetadata?.ziellandZielflughaefen.find(fh => fh.isPrio)?.flughafenIataCode,
          ],
          valueDiffersSeason: [
            !this.areZiellandFlughaefenEqual(
              this.activeMetadata?.ziellandZielflughaefen.map(fh => fh.flughafenIataCode),
              this.activeReisetermin.gueltigeSaison?.ziellandZielflughaefen.map(fh => fh.flughafenIataCode)
            ),
            this.activeMetadata?.ziellandZielflughaefen.find(fh => fh.isPrio)?.flughafenIataCode !==
              this.activeReisetermin.gueltigeSaison?.ziellandZielflughaefen.find(fh => fh.isPrio)
                ?.flughafenIataCode,
          ],
          valueSeason: [
            this.activeReisetermin.gueltigeSaison?.ziellandZielflughaefen.map(fh => fh.flughafenIataCode),
            this.activeReisetermin.gueltigeSaison?.ziellandZielflughaefen.find(fh => fh.isPrio)
              ?.flughafenIataCode,
          ],
          renderer: null,
          editable: [true, true],
          editType: ['flughafen', 'select'],
          split: true,
          options: this.flugdaten?.find(item => item.label === 'Zielland Zielflughäfen / Prio')
            ?.valueEdited[0],
          paramLabel: ['ziellandZielflughaefenIataCodes', 'ziellandZielflughafenPrio'],
        },
        {
          label: ['Zielland Abflughäfen', 'Prio'],
          value: [
            this.activeMetadata?.ziellandAbflughaefen.map(fh => fh.flughafenIataCode),
            this.activeMetadata?.ziellandAbflughaefen.find(fh => fh.isPrio)?.flughafenIataCode,
          ],
          valueEdited: [
            this.activeMetadata?.ziellandAbflughaefen.map(fh => fh.flughafenIataCode),
            this.activeMetadata?.ziellandAbflughaefen.find(fh => fh.isPrio)?.flughafenIataCode,
          ],
          valueDiffersSeason: [
            !this.areZiellandFlughaefenEqual(
              this.activeMetadata?.ziellandAbflughaefen.map(fh => fh.flughafenIataCode),
              this.activeReisetermin.gueltigeSaison?.ziellandAbflughaefen.map(fh => fh.flughafenIataCode)
            ),
            this.activeMetadata?.ziellandAbflughaefen.find(fh => fh.isPrio)?.flughafenIataCode !==
              this.activeReisetermin.gueltigeSaison?.ziellandAbflughaefen.find(fh => fh.isPrio)
                ?.flughafenIataCode,
          ],
          valueSeason: [
            this.activeReisetermin.gueltigeSaison?.ziellandAbflughaefen.map(fh => fh.flughafenIataCode),
            this.activeReisetermin.gueltigeSaison?.ziellandAbflughaefen.find(fh => fh.isPrio)
              ?.flughafenIataCode,
          ],
          renderer: null,
          editable: [true, true],
          editType: ['flughafen', 'select'],
          split: true,
          options: this.flugdaten?.find(item => item.label === 'Zielland Abflughäfen / Prio')?.valueEdited[0],
          paramLabel: ['ziellandAbflughaefenIataCodes', 'ziellandAbflughafenPrio'],
        },
        {
          label: ['OUTB', 'INB'],
          value: [this.activeMetadata?.overnightOutbound, this.activeMetadata?.overnightInbound],
          valueEdited: [this.activeMetadata?.overnightOutbound, this.activeMetadata?.overnightInbound],
          valueDiffersSeason: [
            this.activeMetadata?.overnightOutbound !==
              this.activeReisetermin.gueltigeSaison?.overnightOutbound,
            this.activeMetadata?.overnightInbound !== this.activeReisetermin.gueltigeSaison?.overnightInbound,
          ],
          valueSeason: [
            this.activeReisetermin.gueltigeSaison?.overnightOutbound,
            this.activeReisetermin.gueltigeSaison?.overnightInbound,
          ],
          renderer: null,
          editable: [true, true],
          editType: ['select', 'select'],
          split: true,
          options: [
            { text: '0', value: 0 },
            { text: '+1', value: 1 },
            { text: '+2', value: 2 },
          ],
          paramLabel: ['overnightOutbound', 'overnightInbound'],
        },
        {
          label: 'Hotelstart vor Landung',
          value: !!this.activeMetadata?.hotelstartVorLandung,
          valueEdited: !!this.activeMetadata?.hotelstartVorLandung,
          valueDiffersSeason:
            !!this.activeMetadata?.hotelstartVorLandung !==
            !!this.activeReisetermin.gueltigeSaison.hotelstartVorLandung,
          valueSeason: !!this.activeReisetermin.gueltigeSaison.hotelstartVorLandung,
          renderer: null,
          editable: true,
          editType: 'checkbox',
          paramLabel: 'hotelstartVorLandung',
        },
        {
          label: 'Rückflug nach Hotelende',
          value: !!this.activeMetadata?.rueckflugNachHotelende,
          valueEdited: !!this.activeMetadata?.rueckflugNachHotelende,
          valueDiffersSeason:
            !!this.activeMetadata?.rueckflugNachHotelende !==
            !!this.activeReisetermin.gueltigeSaison.rueckflugNachHotelende,
          valueSeason: !!this.activeReisetermin.gueltigeSaison.rueckflugNachHotelende,
          renderer: null,
          editable: true,
          editType: 'checkbox',
          paramLabel: 'rueckflugNachHotelende',
        },
        {
          label: ['Verkehrstag Hin', 'Rück'],
          value: [this.activeReisetermin?.verkehrstagHin, this.activeReisetermin?.verkehrstagRueck],
          valueEdited: [this.activeReisetermin?.verkehrstagHin, this.activeReisetermin?.verkehrstagRueck],
          renderer: null,
          editable: [false, false],
          split: true,
          paramLabel: ['verkehrstagHin', 'verkehrstagRück'],
        },
        {
          label: ['Hin: Früheste', 'Späteste Ankunft'],
          value: [this.activeMetadata?.fruehesteAnkunft, this.activeMetadata?.spaetesteAnkunft],
          valueEdited: [this.activeMetadata?.fruehesteAnkunft, this.activeMetadata?.spaetesteAnkunft],
          valueDiffersSeason: [
            this.activeMetadata?.fruehesteAnkunft?.substring(0, 5) !==
              this.activeReisetermin?.gueltigeSaison?.fruehesteAnkunft?.substring(0, 5),
            this.activeMetadata?.spaetesteAnkunft?.substring(0, 5) !==
              this.activeReisetermin?.gueltigeSaison?.spaetesteAnkunft?.substring(0, 5),
          ],
          valueSeason: [
            this.activeReisetermin?.gueltigeSaison?.fruehesteAnkunft?.substring(0, 5),
            this.activeReisetermin?.gueltigeSaison?.spaetesteAnkunft?.substring(0, 5),
          ],
          renderer: null,
          editable: [true, true],
          editType: ['time', 'time'],
          split: true,
          paramLabel: ['fruehesteAnkunft', 'spaetesteAnkunft'],
        },
        {
          label: 'Restriktionen Hin Rollover',
          value: Boolean(this.activeMetadata?.restriktionenHinRollover),
          valueEdited: Boolean(this.activeMetadata?.restriktionenHinRollover),
          valueDiffersSeason:
            Boolean(this.activeMetadata?.restriktionenHinRollover) !==
            Boolean(this.activeReisetermin.gueltigeSaison?.restriktionenHinRollover),
          valueSeason: Boolean(this.activeReisetermin?.gueltigeSaison?.restriktionenHinRollover),
          renderer: null,
          editable: true,
          editType: 'checkbox',
          split: false,
          append: '+1',
          paramLabel: 'restriktionenHinRollover',
        },
        {
          label: ['Rück: Frühester', 'Spätester Abflug'],
          value: [this.activeMetadata?.fruehesterAbflug, this.activeMetadata?.spaetesterAbflug],
          valueEdited: [this.activeMetadata?.fruehesterAbflug, this.activeMetadata?.spaetesterAbflug],
          valueDiffersSeason: [
            this.activeMetadata?.fruehesterAbflug?.substring(0, 5) !==
              this.activeReisetermin?.gueltigeSaison?.fruehesterAbflug?.substring(0, 5),
            this.activeMetadata?.spaetesterAbflug?.substring(0, 5) !==
              this.activeReisetermin?.gueltigeSaison?.spaetesterAbflug?.substring(0, 5),
          ],
          valueSeason: [
            this.activeReisetermin?.gueltigeSaison?.fruehesterAbflug?.substring(0, 5),
            this.activeReisetermin?.gueltigeSaison?.spaetesterAbflug?.substring(0, 5),
          ],
          renderer: null,
          editable: [true, true],
          editType: ['time', 'time'],
          split: true,
          paramLabel: ['fruehesterAbflug', 'spaetesterAbflug'],
        },
        {
          label: 'Restriktionen Rück Rollover',
          value: Boolean(this.activeMetadata?.restriktionenRueckRollover),
          valueEdited: Boolean(this.activeMetadata?.restriktionenRueckRollover),
          valueDiffersSeason:
            Boolean(this.activeMetadata?.restriktionenRueckRollover) !==
            Boolean(this.activeReisetermin.gueltigeSaison?.restriktionenRueckRollover),
          valueSeason: Boolean(this.activeReisetermin?.gueltigeSaison?.restriktionenRueckRollover),
          renderer: null,
          editable: true,
          editType: 'checkbox',
          split: false,
          append: '+1',

          paramLabel: 'restriktionenRueckRollover',
        },
        {
          label: ['TH Langstrecke', 'TH Inlandsflug'],
          value: [this.activeReisetermin.thLangstrecke, this.activeReisetermin.thInlandsflug],
          valueEdited: [this.activeReisetermin.thLangstrecke, this.activeReisetermin.thInlandsflug],
          renderer: null,
          editable: [true, true],
          editType: ['number', 'number'],
          split: true,
          paramLabel: ['thLangstrecke', 'thInlandsflug'],
          append: ['€', '€'],
        },
        {
          label: ['TH Max Langstrecke', 'TH Max Inlandsflug'],
          value: [
            this.activeReisetermin?.gueltigerSaisonpreis?.thMaxLangstrecke ?? '-',
            this.activeReisetermin?.gueltigerSaisonpreis?.thMaxInlandsflug ?? '-',
          ],
          renderer: null,
          editable: [false, false],
          editType: ['number', 'number'],
          split: true,
          append: ['€', '€'],
        },
        {
          label: 'VK Max',
          value: this.activeReisetermin?.gueltigerSaisonpreis?.vkMax ?? '-',
          renderer: null,
          editable: false,
          editType: 'number',
          append: '€',
        },
      ];
    },
    async saveChanges() {
      // Change Metadata if it was changed
      if (!this.noMetadataChanges) {
        this.isLoading = true;
        const timeOnlyParams = [
          'fruehesteAnkunft',
          'spaetesteAnkunft',
          'fruehesterAbflug',
          'spaetesterAbflug',
        ];
        const params = this.formatParamsMetadata();
        const formattedParams = {
          [this.activeReisetermin.startdatum]: Object.keys(params).reduce((acc, curr) => {
            if (timeOnlyParams.includes(curr)) {
              // add seconds so that .net accepts this value as TimeOnly
              acc[curr] = params[curr]?.length === 5 ? params[curr] + ':00' : params[curr];
            } else {
              acc[curr] = params[curr];
            }
            return acc;
          }, {}),
        };
        const reisekuerzel = this.activeReisetermin.reiseterminkuerzel.substring(0, 6);

        await this.$store
          .dispatch(EDIT_REISETERMIN_METADATA_MULTIPLE, { formattedParams, reisekuerzel })
          .then(res => {
            this.isLoading = false;
            this.toast('Erfolgreich bearbeitet.');
            this.$emit('reiseterminMetadataBearbeitet', res.data.result.value);
          })
          .catch(() => (this.isLoading = false));
      }
      // Change Reisetermin Data if it was changed
      if (!this.noReiseterminDataChanges) {
        await this.saveChangesReisetermin();
      }
      // If there is an inlandsflug that is ready to save
      if (!this.noReadyLocalInlandsflug) {
        this.isLoading = true;
        const timeOnlyParams = ['spaetesteAnkunft', 'fruehesterAbflug'];
        const params = Object.keys(this.localInlandsflug).reduce((acc, curr) => {
          if (curr === 'isOnlyLocal' || curr === 'id' || curr === 'valuesBeforeEdit') return acc;
          else if (['zielflughafenIataCode', 'abflughafenIataCode'].includes(curr)) {
            acc[curr] = this.localInlandsflug[curr].join();
          } else if (timeOnlyParams.includes(curr) && this.localInlandsflug[curr]) {
            // add seconds so that .net accepts this value as TimeOnly
            acc[curr] = this.localInlandsflug[curr] + ':00';
          } else {
            acc[curr] = this.localInlandsflug[curr];
          }
          return acc;
        }, {});
        apiService
          .post(
            `/Reise/${this.activeReisetermin.reise.reisekuerzel}/metadata/${this.activeReisetermin.startdatum}/inlandsflug/reisetermin`,
            params
          )
          .then(resp => {
            const newInlandsflug = resp.data.result.value;
            this.isLoading = false;
            // insert new inlandsflug
            this.removeLocalInlandsfluege();
            this.addNewInlandsflug(newInlandsflug);
            this.$emit('createdInlandsflug', {
              reiseterminkuerzel: this.activeReisetermin.reiseterminkuerzel,
              newInlandsflug,
            });
          })
          .catch(err => {
            this.isLoading = false;
            this.removeLocalInlandsfluege();
          });
      }
      // Change Inlandsflug Data if it was changed
      if (!this.noInlandsflugChanges) {
        this.isLoading = true;
        const changedInlandsfluege = this.inlandsfluegeChanged;
        for (var inlandsflug of changedInlandsfluege) {
          await this.saveChangesInlandsflug(inlandsflug);
          this.initInlandsfluegeAndReiseterminData();
        }
        this.isLoading = false;
      }

      this.setReisetermindaten();
      this.setReisedaten();
      this.setFlugdaten();
    },
    removeLocalInlandsfluege() {
      this.inlandsfluege = this.inlandsfluege.filter(inlandsflug => !inlandsflug?.isOnlyLocal);
    },
    addNewInlandsflug(inlandsflug) {
      this.inlandsfluege = [
        {
          ...inlandsflug,
          spaetesteAnkunft: inlandsflug.spaetesteAnkunft?.substring(0, 5),
          fruehesterAbflug: inlandsflug.fruehesterAbflug?.substring(0, 5),
          matchingSeasonInlandsflug: this.findMatchingSeasonInlandsflug(
            inlandsflug.tag,
            inlandsflug.abflughafenIataCode
          ),
          valuesBeforeEdit: { ...inlandsflug },
        },
        ...this.inlandsfluege,
      ].sort((a, b) => (a.tag === b.tag ? 0 : a.tag > b.tag ? 1 : -1));
      this.$emit('newInlandsflug', inlandsflug);
    },
    saveChangesInlandsflug(inlandsflug) {
      const reisekuerzel = this.activeReisetermin.reiseterminkuerzel.substring(0, 6);
      const { startdatum } = this.activeReisetermin;
      return apiService
        .put(
          `Reise/${reisekuerzel}/metadata/${startdatum}/inlandsflug/reisetermin?inlandsflugId=${inlandsflug.id}`,
          {
            tag: inlandsflug.tag,
            einkauf: inlandsflug.einkauf,
            fruehesterAbflug: inlandsflug.fruehesterAbflug?.substring(0, 5) + ':00',
            spaetesteAnkunft: inlandsflug.spaetesteAnkunft?.substring(0, 5) + ':00',
            zielflughafenIataCode:
              typeof inlandsflug.zielflughafenIataCode === 'string'
                ? inlandsflug.zielflughafenIataCode
                : inlandsflug.zielflughafenIataCode.join(),
            abflughafenIataCode:
              typeof inlandsflug.abflughafenIataCode === 'string'
                ? inlandsflug.abflughafenIataCode
                : inlandsflug.abflughafenIataCode.join(),
          }
        )
        .then(res => {
          this.toast('Erfolgreich bearbeitet.');
          this.$emit('inlandsflugBearbeitet', {
            reiseterminkuerzel: this.activeReisetermin.reiseterminkuerzel,
            inlandsflug: { ...res.data.result.value },
          });
        });
    },
    openModalWithData() {
      this.prefixedVerlinkungen = [
        {
          id: 0,
          typ: 'Reisetermin',
          artifactId: this.activeReisetermin.id,
          artifactText: this.activeReisetermin.reiseterminkuerzel,
        },
      ];
      this.$emit('showModalAufgabeEdit', this.prefixedVerlinkungen);
    },
    formatDate(date) {
      return format(date, 'dd.MM.yyyy');
    },
    onShow() {
      this.addKeyEventListener();
      this.initInlandsfluegeAndReiseterminData();
    },
    onHidden() {
      this.$emit('sidebarClosed');
      this.removeKeyEventListener();
    },
    goUpOneRow() {
      this.activeComments = false;
      this.$emit('rowSelectionChanged', 'up');
      setTimeout(() => {
        this.activeComments = true;
      }, 100);
    },
    goDownOneRow() {
      this.activeComments = false;
      this.$emit('rowSelectionChanged', 'down');
      setTimeout(() => {
        this.activeComments = true;
      }, 100);
    },
    handleKeyPress($event) {
      if ($event.key === 'ArrowLeft') {
        this.goUpOneRow();
      } else if ($event.key === 'ArrowRight') {
        this.goDownOneRow();
      }
    },
    addKeyEventListener() {
      window.addEventListener('keydown', this.handleKeyPress);
    },
    removeKeyEventListener() {
      window.removeEventListener('keydown', this.handleKeyPress);
    },
    initInlandsfluegeAndReiseterminData() {
      this.inlandsfluege = this.activeMetadata?.inlandsfluege
        ?.slice()
        .map(inlf => ({
          ...inlf,
          matchingSeasonInlandsflug: this.findMatchingSeasonInlandsflug(inlf.tag, inlf.abflughafenIataCode),
          valuesBeforeEdit: { ...inlf },
        }))
        .sort((a, b) => (a.tag === b.tag ? 0 : a.tag > b.tag ? 1 : -1));
      this.vorzeitigGarantiert = this.activeReisetermin?.vorzeitigGarantiert;
      this.istZusatztermin = this.activeReisetermin?.istZusatztermin;
      this.thInlandsflug = this.activeReisetermin?.thInlandsflug;
      this.thLangstrecke = this.activeReisetermin?.thLangstrecke;
    },
    saveChangesReisetermin() {
      const reisekuerzel = this.activeReisetermin?.reiseterminkuerzel.substring(0, 6);
      const startdatum = this.activeReisetermin?.startdatum;
      const vorzeitigGarantiert = this.reisetermindaten.find(
        item => item.label === 'Vorzeitig garantiert'
      )?.valueEdited;
      const istZusatztermin = this.reisetermindaten.find(item => item.label === 'Zusatztermin')?.valueEdited;
      const [thLangstrecke, thInlandsflug] = this.flugdaten.find(
        item => item.label?.[0] === 'TH Langstrecke'
      )?.valueEdited;
      this.isLoading = true;
      return apiService
        .put(`Reise/${reisekuerzel}/Reisetermin/${startdatum}/`, {
          vorzeitigGarantiert,
          thInlandsflug,
          thLangstrecke,
          reisekuerzel,
          startdatum,
          istZusatztermin,
        })
        .then(() => {
          this.toast('Erfolgreich bearbeitet.');
          this.$emit(
            'reiseterminBearbeitet',
            this.activeReisetermin.reiseterminkuerzel,
            vorzeitigGarantiert,
            thLangstrecke,
            thInlandsflug,
            istZusatztermin
          );
          this.isLoading = false;
        });
    },
    onButtonStatusBearbeiten(hide) {
      this.$root.$emit('bv::toggle::modal', 'modalStatusAendern');
    },
    areZiellandFlughaefenEqual(ziellandFlughaefen, ziellandFlughaefenSeason) {
      if (ziellandFlughaefen === null && ziellandFlughaefenSeason === null) return true;

      if (Array.isArray(ziellandFlughaefen) && Array.isArray(ziellandFlughaefenSeason)) {
        const sortedZiellandFlughaefen = ziellandFlughaefen.slice().sort();
        const sortedZiellandFlughaefenSeason = ziellandFlughaefenSeason.slice().sort();
        return this.areListsEqual(sortedZiellandFlughaefen, sortedZiellandFlughaefenSeason);
      }

      return false;
    },
    areListsEqual(list1, list2) {
      if (list1.length !== list2.length) {
        return false;
      }

      for (let i = 0; i < list1.length; i++) {
        if (list1[i] !== list2[i]) {
          return false;
        }
      }

      return true;
    },
    async onClickDeleteReisetermin(hide) {
      const confirmDeletion = await this.$bvModal.msgBoxConfirm(
        `Möchtest du den Reisetermin ${this.activeReisetermin.reiseterminkuerzel} wirklich löschen?`,
        {
          centered: true,
          okTitle: 'Löschen',
          okVariant: 'danger',
          cancelTitle: 'Abbrechen',
        }
      );
      if (confirmDeletion) {
        this.$emit('reiseterminLoeschen');

        hide();
      }
    },
    getVerkehrstagOfInlandsflug(reisetermin, inlandsflug) {
      const dayOfInlandsflug = add(new Date(reisetermin.abreisedatum), { days: +inlandsflug.tag - 1 });
      const verkehrstag = getISODay(dayOfInlandsflug);
      return verkehrstag;
    },
    getDateOfIlandsflug(reisetermin, inlandsflug) {
      const dateOfInlandsfluge = add(new Date(reisetermin.abreisedatum), { days: +inlandsflug.tag - 1 });

      return format(dateOfInlandsfluge, 'dd.MM.yyyy');
    },
    getBlankSyncState,
  },
};
</script>
<style scoped>
.navigationIcons {
  color: black;
}
.navigationIcons:hover {
  color: #0d6efd;
  cursor: pointer;
}
.normalFormatted {
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.detailLabelGreyedOut {
  background-color: rgba(114, 114, 114, 0.05);
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.leftLabel {
  font-size: 1.1rem;
  font-weight: 500;
}
.list-group {
  height: 42px;
}
:deep(.form-control) {
  height: 32px !important;
}
.plus-sign {
  height: 24px;
  width: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.tooltip {
  opacity: 1 !important;
}
</style>
